import { FileInput } from '@uppy/react';
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePrevious } from 'react-use';

import {
  type DtoGamePackUGCFile,
  EnumsGamePackUGCFileStatus,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import { useInstance } from '../../../hooks/useInstance';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { fromMediaDTO } from '../../../utils/api-dto';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { Chan } from '../../../utils/Chan';
import { assertExhaustive, err2s } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { AIIcon } from '../../icons/AIIcon';
import { AttachIcon } from '../../icons/AttachIcon';
import { ChatSendIcon } from '../../icons/Chat/ChatSendIcon';
import { DocumentIcon } from '../../icons/DocumentIcon';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { StopIcon } from '../../icons/StopIcon';
import { TimerIcon } from '../../icons/TimerIcon';
import { XIcon } from '../../icons/XIcon';
import { Loading } from '../../Loading';
import {
  useIsUGCFileManagerDraging,
  useIsUGCFileManagerEnabled,
  useUGCFileManager,
  useUGCFiles,
} from './CustomGameFileManager';
import { CustomGameThinkingHint } from './CustomGameThinkingHint';
import {
  type LocalUGCFile,
  type Message,
  type RemoteUGCFile,
  type UGCFile,
} from './types';
import { useModifierKeySymbol } from './utils';

function SimpleFilePreview(props: { fileName: string }) {
  return (
    <div className='w-full h-full bg-[#8C6FFF] flex items-center justify-center p-1 rounded-lg'>
      <p className='text-3xs text-white font-bold truncate'>{props.fileName}</p>
    </div>
  );
}

function MediaPreview(props: { fileName: string; media: Media }) {
  const { fileName, media } = props;

  switch (media.type) {
    case MediaType.Image:
      return (
        <img
          src={MediaUtils.PickMediaUrl(media) || ''}
          alt='preview'
          className='w-full h-full rounded-lg object-contain'
        />
      );
    case MediaType.Video:
      return (
        <video
          src={media.url}
          className='w-full h-full rounded-lg object-contain'
          preload={'none'}
          poster={media.firstThumbnailUrl ?? ''}
        />
      );
    case MediaType.Audio:
      return <SimpleFilePreview fileName={fileName} />;
    default:
      assertExhaustive(media.type);
      return null;
  }
}

function LocalFileIcon(props: {
  status: LocalUGCFile['status'];
  className?: string;
}) {
  switch (props.status) {
    case 'none':
      return <TimerIcon className={`${props.className} fill-current`} />;
    case 'uploading':
      return <Loading text='' imgClassName={`${props.className}`} />;
    case 'failed':
      return <ErrorIcon className={`${props.className} fill-[#EE3529]`} />;
    default:
      assertExhaustive(props.status);
      return null;
  }
}

function useLocalFileLabel(status: LocalUGCFile['status']) {
  switch (status) {
    case 'none':
    case 'uploading':
      return 'Uploading';
    case 'failed':
      return `Upload failed`;
    default:
      assertExhaustive(status);
      return null;
  }
}

function LocalUGCFileAttachment(props: {
  file: LocalUGCFile;
  onDelete: () => void;
}) {
  const { status, file } = props.file;
  const label = useLocalFileLabel(status);
  return (
    <div
      className='w-25 h-14 flex-shrink-0 border border-secondary relative
      rounded-lg'
    >
      <SimpleFilePreview fileName={file.name} />
      <div className='absolute -right-1.5 -top-1.5 w-5 h-5 text-white group'>
        <LocalFileIcon
          status={status}
          className={`w-full h-full block ${
            status !== 'uploading' ? 'group-hover:hidden' : ''
          }`}
        />
        <button
          type='button'
          className={`w-full h-full bg-light-gray hidden ${
            status !== 'uploading' ? 'group-hover:flex' : ''
          } items-center justify-center text-white rounded-full`}
          onClick={props.onDelete}
        >
          <XIcon className='w-3 h-3 fill-current' />
        </button>
      </div>
      {label && (
        <div
          className='absolute bottom-0 text-white text-3xs text-center 
      w-full bg-black bg-opacity-25 py-0.5'
        >
          {label}
        </div>
      )}
    </div>
  );
}

function RemoteFileIcon(props: {
  status: DtoGamePackUGCFile['status'];
  className?: string;
}) {
  switch (props.status) {
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusPreProcessing:
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusInProgress:
      return <Loading text='' imgClassName={`${props.className}`} />;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusFailed:
      return <ErrorIcon className={`${props.className} fill-[#EE3529]`} />;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusCompleted:
      return null;
    default:
      assertExhaustive(props.status);
      return null;
  }
}

function useRemoteFileLabel(status: DtoGamePackUGCFile['status']) {
  switch (status) {
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusPreProcessing:
      return 'Preparing';
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusInProgress:
      return 'Analyzing';
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusFailed:
      return null;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusCompleted:
      return null;
    default:
      assertExhaustive(status);
      return null;
  }
}

function RemoteUGCFileAttachment(props: {
  file: RemoteUGCFile;
  onDelete: () => void;
  onDownload: () => void;
}) {
  const { file } = props.file;
  const label = useRemoteFileLabel(file.status);

  return (
    <div
      className='w-25 h-14 flex-shrink-0 border border-secondary relative
      rounded-lg group/1'
    >
      {file.media?.media ? (
        <MediaPreview
          fileName={file.name}
          media={fromMediaDTO(file.media.media)}
        />
      ) : (
        <SimpleFilePreview fileName={file.name} />
      )}
      <div
        className='absolute inset-0 w-full h-full rounded-lg bg-secondary 
      bg-opacity-40 items-center justify-center hidden group-hover/1:flex z-5'
      >
        <button
          type='button'
          className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
        text-white'
          onClick={props.onDownload}
        >
          <NewWindowIcon className='w-5 h-5 fill-current' />
        </button>
      </div>
      <div className='absolute -right-1.5 -top-1.5 w-5 h-5 text-white group z-10'>
        <RemoteFileIcon
          status={file.status}
          className='w-full h-full block group-hover:hidden'
        />
        <button
          type='button'
          className='w-full h-full bg-light-gray hidden group-hover:flex 
          items-center justify-center text-white rounded-full'
          onClick={props.onDelete}
        >
          <XIcon className='w-3 h-3 fill-current' />
        </button>
      </div>
      {label && (
        <div
          className='absolute bottom-0 text-white text-3xs text-center 
      w-full bg-black bg-opacity-25 py-0.5'
        >
          {label}
        </div>
      )}
    </div>
  );
}

function UGCFileAttachment(props: {
  file: UGCFile;
  onDelete: () => void;
  onDownload: () => void;
}) {
  return props.file.remote ? (
    <RemoteUGCFileAttachment
      file={props.file}
      onDelete={props.onDelete}
      onDownload={props.onDownload}
    />
  ) : (
    <LocalUGCFileAttachment file={props.file} onDelete={props.onDelete} />
  );
}

export type ActionProps = {
  prompt: string;
  disabled: boolean;
  disabledReason?: 'submitting' | 'filesNotReady' | 'emptyPrompt';
  submit: () => Promise<void>;
  abort: () => void;
};

export type PromptSuggestion = {
  label: string;
  value: string;
};

export type CustomEditorPrimaryAction = (props: ActionProps) => JSX.Element;

type EditorProps = {
  wrapperClassName?: string;
  enabled: boolean;
  onSubmit: (prompt: string) => Promise<boolean>;
  onAbort: () => void;
  isSubmitting: boolean;
  active?: boolean;
  autoFocus?: boolean;
  ctxLabel?: string;
  error?: unknown;
  enableThinkingHint?: boolean;
  accessory?: ReactNode;
  placeholder?: string;
  showOverlayWhenActive?: boolean;
  disableDeactivate?: boolean;
  width?: string;
  children?: CustomEditorPrimaryAction;
  borderVariant?: 'secondary' | 'gradient' | 'luna-gradient';
  bottomLabel?: string;
  alternateButton?: boolean;
  innerBottomLabel?: string;
  suggestions?: PromptSuggestion[];
  height?: {
    active: {
      withFiles?: string;
      withoutFiles?: string;
    };
    inactive?: string;
  };
};

type PromptSuggestionItemProps = {
  suggestion: PromptSuggestion;
  onClick: (value: string) => void;
};

function PromptSuggestionItem({
  suggestion,
  onClick,
}: PromptSuggestionItemProps) {
  return (
    <div
      className='
        rounded-full 
        p-2 
        font-bold 
        bg-main-layer 
        text-icon-gray 
        border 
        text-xs
        border-secondary 
        text-center 
        cursor-pointer 
        hover:bg-gray-100 
        transition-colors duration-200 ease-in-out 
        sm:whitespace-nowrap 
        sm:overflow-hidden 
        sm:truncate
      '
      onClick={() => onClick(suggestion.value)}
    >
      <span className='italic font-bold'>{suggestion.label}</span>
    </div>
  );
}

function DefaultAction(props: ActionProps) {
  const { disabled, disabledReason, submit, abort } = props;
  return disabledReason === 'submitting' ? (
    <button
      type='button'
      className={`bg-luna-primary text-black w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0 hover:opacity-90 transition-opacity`}
      onClick={abort}
    >
      <StopIcon />
    </button>
  ) : (
    <button
      type='button'
      className={`${
        disabled ? 'bg-gray-500 opacity-50' : 'bg-luna-primary hover:opacity-90'
      } text-black w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0 transition-opacity`}
      disabled={disabled}
      onClick={submit}
      title={
        disabledReason === 'filesNotReady'
          ? 'Wait for files to be ready'
          : undefined
      }
    >
      <ChatSendIcon />
    </button>
  );
}

export function CustomGamePackPromptEditor(props: EditorProps) {
  const { onSubmit, isSubmitting, error } = props;
  const [prompt, setPrompt] = useState('');
  const [active, setActive] = useState(!!props.active);
  const ref = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<Message | null>(null);
  const modSym = useModifierKeySymbol();
  const fileman = useUGCFileManager();
  const files = useUGCFiles();
  const draging = useIsUGCFileManagerDraging();
  const fileUploadEnabled = useIsUGCFileManagerEnabled();
  const hasIncompleteFiles = files.some((f) =>
    f.remote ? f.file.status !== 'completed' : true
  );
  const hasInProgressFiles = files.some((f) =>
    f.remote
      ? f.file.status === 'pre_processing' || f.file.status === 'in_progress'
      : f.status === 'uploading'
  );
  const [shouldRefocus, setShouldRefocus] = useState(Boolean(props.autoFocus));

  const toggleActive = (value: boolean) => {
    if (props.disableDeactivate) return;
    setActive(value);
  };

  const toggle = () => {
    if (active) {
      toggleActive(false);
    } else {
      toggleActive(true);
      requestAnimationFrame(() => ref.current?.focus());
    }
  };

  const submit = async () => {
    setPrompt('');
    ref.current?.blur();
    const succeeded = await onSubmit(prompt);
    setShouldRefocus(true);
    if (!succeeded) {
      setPrompt(prompt);
      return;
    }
    toggleActive(false);
  };

  useEffect(() => {
    if (shouldRefocus) {
      requestAnimationFrame(() => {
        ref.current?.focus();
        setShouldRefocus(false);
      });
    }
  }, [shouldRefocus]);

  const onKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) return;
      if (hasInProgressFiles) {
        chan.put({ type: 'error', detail: 'Files are not ready yet' });
        e.preventDefault();
        return;
      }
      if (prompt.trim() === '') {
        chan.put({
          type: 'success',
          detail: 'Tell us more of what you want to generate.',
        });
        e.preventDefault();
        return;
      }
      await submit();
    } else if (e.key === 'Escape') {
      toggleActive(false);
    }
  };

  const chan = useInstance(() => new Chan<Message>());

  useEffect(() => {
    const ctrl = new BrowserTimeoutCtrl();
    async function tick() {
      const next = await chan.take();
      if (!next) return;
      setMessage(next);
      ctrl.set(() => {
        setMessage(null);
        tick();
      }, 3000);
    }
    tick();
    return () => ctrl.clear();
  }, [chan]);

  useEffect(() => {
    if (!error) return;
    chan.put({ type: 'error', detail: err2s(error) ?? '' });
  }, [chan, error]);

  useEffect(() => {
    return fileman.on('message', (msg) => {
      chan.put(msg);
    });
  }, [chan, fileman]);

  useOutsideClick(containerRef, () => {
    if (isSubmitting) return;
    toggleActive(false);
  });

  useHotkeys('mod+k', () => toggle(), {
    enabled: !isSubmitting,
    enableOnFormTags: ['TEXTAREA'],
  });

  const dropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!dropRef.current || !fileUploadEnabled) return;
    return fileman.enableDrapDrop(dropRef.current);
  }, [fileUploadEnabled, fileman]);

  const fileListRef = useRef<HTMLDivElement>(null);

  const currNumFiles = files.length;
  const prevNumFiles = usePrevious(currNumFiles);
  useEffect(() => {
    if (!fileListRef.current) return;
    if (currNumFiles > (prevNumFiles ?? 0)) {
      fileListRef.current.scrollLeft = fileListRef.current.scrollWidth;
    }
  }, [currNumFiles, files, prevNumFiles]);

  const [actionDisabled, actionDisabledReason] = useMemo<
    [boolean, ActionProps['disabledReason']]
  >(() => {
    if (isSubmitting) return [true, 'submitting'];
    if (hasInProgressFiles) return [true, 'filesNotReady'];
    if (prompt.trim() === '') return [true, 'emptyPrompt'];
    return [false, undefined];
  }, [isSubmitting, hasInProgressFiles, prompt]);

  if (!props.enabled) return <div className='h-10'></div>;

  const Action = props.children ?? DefaultAction;

  const borderClassName =
    props.borderVariant === 'gradient'
      ? 'p-[1px] bg-gradient-to-tr from-[#FE0653] to-[#0029FF]'
      : props.borderVariant === 'luna-gradient'
      ? 'p-[1px] bg-gradient-to-r from-luna-gradient-start to-luna-gradient-end'
      : 'border border-secondary';

  const getHeightClass = () => {
    if (!active) {
      return props.height?.inactive ?? 'h-10';
    }

    if (files.length > 0) {
      return props.height?.active.withFiles ?? 'h-50';
    }

    return props.height?.active.withoutFiles ?? 'h-30';
  };

  return (
    <>
      <div
        className={`w-full flex flex-col items-center justify-center gap-2 ${
          props.wrapperClassName ?? ''
        }`}
      >
        <div
          ref={containerRef}
          className={`${
            active
              ? `${props.width ?? 'w-160'} ${getHeightClass()}`
              : `w-50 ${getHeightClass()}`
          } transition-size duration-300`}
        >
          <div
            className={`w-full h-full ${active ? 'flex' : 'hidden'} flex-col`}
          >
            <div className='w-full flex-grow relative'>
              <div
                className={`w-full h-full ${borderClassName} rounded-xl 
                  ${props.ctxLabel ? 'rounded-bl-none' : ''} 
                  ${hasIncompleteFiles ? 'rounded-tl-none' : ''}`}
              >
                <div
                  ref={dropRef}
                  className={`w-full h-full flex flex-col bg-dark-gray rounded-xl`}
                >
                  {files.length > 0 && (
                    <div
                      ref={fileListRef}
                      className='w-full h-auto flex items-center px-2 pt-2.5 gap-2 overflow-x-auto'
                      style={{
                        scrollbarColor: '#8b9294 transparent',
                        scrollbarWidth: 'thin',
                      }}
                    >
                      {files.map((file) => (
                        <UGCFileAttachment
                          key={file.file.id}
                          file={file}
                          onDelete={() => fileman.deleteFile(file.file.id)}
                          onDownload={() => fileman.downloadFile(file.file.id)}
                        />
                      ))}
                    </div>
                  )}
                  <textarea
                    ref={ref}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyDown={onKeyDown}
                    className={`w-full flex-grow bg-transparent resize-none outline-none
                     text-white text-sms caret-color-primary px-5 ${
                       files.length > 0 ? 'pt-2 pb-10' : 'pt-4 pb-10'
                     }`}
                    placeholder={props.placeholder}
                    disabled={isSubmitting}
                  />
                  <div className='flex items-center justify-between gap-2 p-2'>
                    {fileUploadEnabled ? (
                      <div>
                        {props.alternateButton ? (
                          <div className='flex-row gap-2 items-center hidden sm:flex text-sms'>
                            <label className='flex flex-row gap-2 text-sms btn-secondary p-2 cursor-pointer'>
                              <DocumentIcon className='w-4.5 h-4.5 fill-current' />
                              Upload Files
                              <div className='hidden w-0 h-0'>
                                <FileInput
                                  uppy={fileman.uppy}
                                  inputName='files'
                                />
                              </div>
                            </label>
                            <span className='text-icon-gray text-xs italic'>
                              {props.innerBottomLabel ??
                                `Files like, PDF, PPT, Videos`}
                            </span>
                          </div>
                        ) : (
                          <label
                            className={`w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0 bg-black border-0 cursor-pointer`}
                          >
                            <AttachIcon />
                            <div className='hidden w-0 h-0'>
                              <FileInput
                                uppy={fileman.uppy}
                                inputName='files'
                              />
                            </div>
                          </label>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className='flex items-center justify-center gap-2'>
                      {isSubmitting && props.enableThinkingHint && (
                        <CustomGameThinkingHint
                          containerClassName='!h-7.5 text-xs !border rounded-md !px-2'
                          loadingClassName='w-5 h-5'
                        />
                      )}
                      <Action
                        prompt={prompt}
                        disabled={actionDisabled}
                        disabledReason={actionDisabledReason}
                        submit={submit}
                        abort={props.onAbort}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props.ctxLabel && (
                <p
                  className='bg-lp-gray-003 text-icon-gray rounded px-2 py-1 
              absolute text-3xs bottom-0 left-0 transform-gpu translate-y-full 
              rounded-t-none'
                >
                  {props.ctxLabel}
                </p>
              )}
              {hasIncompleteFiles && (
                <p
                  className='bg-lp-gray-003 text-white rounded px-2 py-1 
              absolute text-3xs top-0 left-0 transform-gpu -translate-y-full 
              rounded-b-none'
                >
                  Some files are not ready to be used for AI.
                </p>
              )}
              <div
                className={`flex items-center justify-center text-sms 
              px-2 py-1 absolute bottom-1 left-1/2 transform -translate-x-1/2 
              rounded text-white transition-opacity ${
                message ? 'opacity-100' : 'opacity-0'
              } ${
                  message?.type === 'error'
                    ? 'bg-error'
                    : message?.type === 'success'
                    ? 'bg-lp-green-001'
                    : 'bg-secondary'
                }`}
              >
                {message?.detail}
              </div>
              <div
                className={`bg-[#FBB7071A] text-tertiary text-sms rounded-xl 
              px-10 py-2 absolute bottom-1 left-1/2 transform -translate-x-1/2 
              transition-opacity ${draging ? 'opacity-100' : 'opacity-0'}`}
              >
                Drop file here to upload
              </div>
              {props.accessory}
            </div>
            {props.suggestions && (
              <div className='flex flex-col gap-2 text-xs my-2 align-middle'>
                <span className='text-icon-gray'>Suggestions:</span>
                <div className='flex flex-wrap gap-1'>
                  {props.suggestions.map((suggestion, i) => (
                    <PromptSuggestionItem
                      key={i}
                      suggestion={suggestion}
                      onClick={(value) => setPrompt(value)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className='text-icon-gray text-3xs mt-1.5 text-center'>
              {props.bottomLabel ??
                'AI Editor can make mistakes. Please check your work.'}
            </div>
          </div>
          <button
            type='button'
            className={`w-full h-full rounded-xl bg-dark-gray border border-secondary 
        relative items-center justify-between p-3 ${
          !active ? 'flex' : 'hidden'
        }`}
            onClick={toggle}
          >
            <div className='flex items-center justify-center text-2xs text-icon-gray gap-2'>
              <AIIcon className='w-3.5 h-3.5 fill-current' />
              <p className='max-w-24 truncate'>
                {prompt ? prompt : 'AI Editor'}
              </p>
            </div>
            <p className='text-sms text-icon-gray'>{modSym}K</p>
          </button>
        </div>
      </div>
      {active && props.showOverlayWhenActive && (
        <div className='w-full h-full bg-black bg-opacity-50 absolute z-[1]'></div>
      )}
    </>
  );
}

export function UGCActivityPromptEditor(props: EditorProps) {
  return (
    <CustomGamePackPromptEditor
      {...props}
      showOverlayWhenActive
      wrapperClassName={`absolute z-5 bottom-4`}
    />
  );
}
